import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/Login')
  },
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/',
    name: '首页',
    component: () => import('@/views/Home'),
    children:[
      {
        path: '/index',
        name: '首页',
        component: () => import('../views/Index.vue')
      },
      {
        path: '/member',
        name: '团队成员',
        component: () => import('../views/Member')
      },
      {
        path: '/job',
        name: '公司招聘',
        component: () => import('@/views/Job')
      },
      {
        path: '/dynamic',
        name: '动态管理',
        component: () => import('@/views/Dynamic')
      },
      {
        path: '/field',
        name: '业务领域',
        component: () =>  import('@/views/Field')
      },
      {
        path: '/focus',
        name: '聚焦与观察',
        component: () =>  import('@/views/Focus')
      },
      {
        path: '/duty',
        name: '社会责任',
        component: () =>  import('@/views/Duty')
      },
      {
        path: '/fund',
        name: '一诺基金',
        component: () =>  import('@/views/Fund')
      },
      {
        path: '/building',
        name: '元诺建材',
        component: () =>  import('@/views/Building')
      },
      {
        path: '/number',
        name: '动态数据',
        component: () =>  import('@/views/Number')
      },
      {
        path: '/about',
        name: '关于元诺',
        component: () => import(/* webpackChunkName: "about" */ '@/views/About')
      },
    ]
  },
  {
    path: '/editor',
    name: '编辑器',
    component: () => import('../views/Editor')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

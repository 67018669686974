import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
// 备注：引入之后会造成CKEditor编辑器内容样式变形
// import '@/assets/reset.css';  
import CKEditor from '@ckeditor/ckeditor5-vue' // 首先导入
import 'element-plus/lib/theme-chalk/index.css'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn' // 中文显示
import 'dayjs/locale/zh-cn' // 日历国际化
// 路由的钩子函数

// request 拦截器
axios.interceptors.request.use(config =>{
    // console.log('我是拦截器 request ===== ', config)
    if(localStorage.token){
        config.headers.authorization = localStorage.token
    }
    return config
},err =>{
    return Promise.reject(err)
})
// response 拦截器
axios.interceptors.response.use(response =>{
    // console.log('我是拦截器 response ===== ', response)
    if(response.data.code == 401){
        router.replace({
            path: 'login'
        })
    }
    return response
}, err =>{
    return Promise.reject(error.response.status)
})
createApp(App).use(store).use(router).use(ElementPlus, {locale}).use(CKEditor).mount('#app')
// createApp(App).use(store).use(router).use(CKEditor).mount('#app')
